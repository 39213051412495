import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'panBankVeification',

  components: {},
  data() {
    return {
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      selectedFile: null,
      panBankVrificationData: [],
      panBankVrificationFields: [
        { key: 'vendor_name' },
        { key: 'vendor_number' },
        { key: 'vendor_number_alt', label: 'Vendor Name ALT' },
        { key: 'site_code', label: 'Vendor Site Code' },
        { key: 'vendor_type' },
        { key: 'creation_date', label: 'Supplier Creation Date' },
        { key: 'partner_id', label: 'D@S Partner Id' },
        { key: 'url', label: 'D@S url' },
        { key: 'pan_number', label: 'Site Pan Number' },
        { key: 'bank_name' },
        { key: 'ifsc_code', label: 'IFSC Code' },
        { key: 'account_number', label: 'Account Number' },
        { key: 'beneficiary_name', label: 'Beneficiary Name' }
      ]
    };
  },
  mounted() {},
  methods: {
    handleFileChange() {},
    handleFileUpload() {
      if (this.selectedFile && this.selectedFile.name.endsWith('.xlsx')) {
        // alert(this.selectedFile);
      } else {
        // Handle invalid file type
        this.selectedFile = null;
        // alert('Please select an XLSX file.');
      }
    },
    clearFile() {
      this.selectedFile = null;
    }
  }
};
